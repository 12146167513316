import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCIUSInvoiceKeys from 'src/constants/locale/key/NbciUsInvoice.js'

function makeValidationSchema(translate) {
  const regexDigit = '^[0-9]{0,8}$'
  const errMsgDigit = 'Please enter only numeric values with up to 8 digits'
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Allowed special characters are  ' + ValidationRegex.errMsgAlphaNumeric

  return Yup.object({
    commonName: Yup.string()
      .nullable()
      .max(30, 'Max value allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    animalNumber: Yup.string()
      .nullable()
      .matches(regexDigit, errMsgDigit)
      .max(8, 'Max value allowed is 8'),
    purposeOfImportation: Yup.string()
      .nullable()
      .max(50, 'Max value allowed is 50')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    sex: Yup.string()
      .nullable()
      .max(6, 'Max value allowed is 06')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
  })
}

export default makeValidationSchema
