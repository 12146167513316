import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCIUSInvoiceKeys from 'src/constants/locale/key/NbciUsInvoice.js'

function makeValidationSchema(translate) {
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Allowed special characters are  ' + ValidationRegex.errMsgAlphaNumeric

  return Yup.object({
    chemicalName: Yup.string()
      .nullable()
      .max(30, 'Max value allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    services: Yup.string()
      .nullable()
      .min(6, 'Min value needed is 6')
      .max(10, 'Max value allowed is 10')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    regNumber: Yup.string()
      .nullable()
      .max(30, 'Max value allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
  })
}

export default makeValidationSchema
