import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CollapsibleSection from '../../../../components/ui/CollapsibleSection'
import FormProperties from './FDAFormFormProperties'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Divider, Grid, Tooltip } from '@material-ui/core'
import FDAFormFieldsPlaceholder from './FDAFormFieldsPlaceholder'
import _ from 'lodash'
import useCustomYupValidationResolver from 'src/components/aciacehighway/hooks/useCustomYupValidationResolver'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues, makeValidationSchema } = FormProperties.formikProps

function FDAFormSection(props) {
  const { title } = props

  const { getValues, setValue, watch } = useFormContext()
  const fdaForm = watch('nbciusinvoiceDetailsFDAForm')
  const generatedIndex = useRef(0)

  function handleAddFDAForm(data) {
    const fdaForm = [...getValues('nbciusinvoiceDetailsFDAForm'), data]

    setValue('nbciusinvoiceDetailsFDAForm', fdaForm)
  }

  function handleDeleteFDAForm(index) {
    const fdaForm = [...getValues('nbciusinvoiceDetailsFDAForm')]

    fdaForm.splice(index, 1)

    setValue('nbciusinvoiceDetailsFDAForm', fdaForm)
  }

  function handleEditFDAForm(index, data) {
    const fdaForm = [...getValues('nbciusinvoiceDetailsFDAForm')]

    fdaForm[index] = data

    setValue('nbciusinvoiceDetailsFDAForm', fdaForm)
  }

  return (
    <CollapsibleSection defaultExpanded={!_.isEmpty(fdaForm)} title={title}>
      <Grid container spacing={2}>
        {_.isEmpty(fdaForm) ? (
          <Grid item xs={12}>
            <FDAFormFieldsPlaceholder onAdd={handleAddFDAForm} />
          </Grid>
        ) : (
          fdaForm.map((form, index) => (
            <React.Fragment key={++generatedIndex.current}>
              <Grid item xs={12}>
                <FDAFormEntry
                  fdaForm={form}
                  onAdd={handleAddFDAForm}
                  onDelete={() => handleDeleteFDAForm(index)}
                  onEdit={(data) => handleEditFDAForm(index, data)}
                />
              </Grid>
              {index + 1 !== fdaForm.length && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CollapsibleSection>
  )
}

function FDAFormEntry(props) {
  const { fdaForm, onAdd, onDelete, onEdit } = props

  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const methods = useForm({
    defaultValues: fdaForm,
    resolver: useCustomYupValidationResolver(makeValidationSchema(translate))
  })
  const {
    formState: { isDirty }
  } = methods

  function handleEditFDAForm(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12} sm>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12} sm='auto'>
          <Grid justify='flex-end' container spacing={1}>
            {isDirty ? (
              <>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    onClick={() => methods.reset()}
                    size='medium'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    onClick={methods.handleSubmit(handleEditFDAForm)}
                    size='medium'
                  >
                    Save changes
                  </CngButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Add'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'plus']}
                        onClick={() => onAdd(initialValues)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Clone'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'copy']}
                        onClick={() => onAdd({ ...fdaForm, id: undefined })}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Delete'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'trash']}
                        onClick={() => onDelete(fdaForm)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default FDAFormSection
