import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CollapsibleSection from '../../../../components/ui/CollapsibleSection'
import FormProperties from './ServiceFormProperties'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Divider, Grid, Tooltip } from '@material-ui/core'
import ServiceFieldsPlaceholder from './ServiceFieldsPlaceholder'
import _ from 'lodash'
import useCustomYupValidationResolver from 'src/components/aciacehighway/hooks/useCustomYupValidationResolver'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues, makeValidationSchema } = FormProperties.formikProps

function ServicesSection(props) {
  const { title } = props

  const { getValues, setValue, watch } = useFormContext()
  const services = watch('nbciusinvoiceDetailsService')
  const generatedIndex = useRef(0)

  function handleAddService(data) {
    const services = [...getValues('nbciusinvoiceDetailsService'), data]

    setValue('nbciusinvoiceDetailsService', services)
  }

  function handleDeleteService(index) {
    const services = [...getValues('nbciusinvoiceDetailsService')]

    services.splice(index, 1)

    setValue('nbciusinvoiceDetailsService', services)
  }

  function handleEditService(index, data) {
    const services = [...getValues('nbciusinvoiceDetailsService')]

    services[index] = data

    setValue('nbciusinvoiceDetailsService', services)
  }

  return (
    <CollapsibleSection defaultExpanded={!_.isEmpty(services)} title={title}>
      <Grid container spacing={2}>
        {_.isEmpty(services) ? (
          <Grid item xs={12}>
            <ServiceFieldsPlaceholder onAdd={handleAddService} />
          </Grid>
        ) : (
          services.map((service, index) => (
            <React.Fragment key={++generatedIndex.current}>
              <Grid item xs={12}>
                <ServiceEntry
                  services={service}
                  onAdd={handleAddService}
                  onDelete={() => handleDeleteService(index)}
                  onEdit={(data) => handleEditService(index, data)}
                />
              </Grid>
              {index + 1 !== services.length && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CollapsibleSection>
  )
}

function ServiceEntry(props) {
  const { services, onAdd, onDelete, onEdit } = props

  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const methods = useForm({
    defaultValues: services,
    resolver: useCustomYupValidationResolver(makeValidationSchema(translate))
  })
  const {
    formState: { isDirty }
  } = methods

  function handleEditService(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12} sm>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12} sm='auto'>
          <Grid justify='flex-end' container spacing={1}>
            {isDirty ? (
              <>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    onClick={() => methods.reset()}
                    size='medium'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    onClick={methods.handleSubmit(handleEditService)}
                    size='medium'
                  >
                    Save changes
                  </CngButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Add'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'plus']}
                        onClick={() => onAdd(initialValues)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Clone'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'copy']}
                        onClick={() => onAdd({ ...services, id: undefined })}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Delete'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'trash']}
                        onClick={() => onDelete(services)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default ServicesSection
