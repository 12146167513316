import { Yup } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCIUSInvoiceKeys from 'src/constants/locale/key/NbciUsInvoice.js'

function makeValidationSchema(translate) {
  const regexDigitWithoutrDecimal = '^[0-9]{0,6}$'
  const errMsgDigitWithoutDecimal =
    'Please enter only numeric values with up to 6 digits without decimal'
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Allowed special characters are  ' + ValidationRegex.errMsgAlphaNumeric

  return Yup.object({
    productDesc: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    quantity: Yup.string()
      .nullable()
      .max(6, 'Max length allowed is 06')
      .matches(regexDigitWithoutrDecimal, errMsgDigitWithoutDecimal),
    modelNo: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    brandName: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
  })
}

export default makeValidationSchema
