import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCIUSInvoiceKeys from 'src/constants/locale/key/NbciUsInvoice.js'

function makeValidationSchema(translate) {
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Allowed special characters are  ' + ValidationRegex.errMsgAlphaNumeric

  return Yup.object({
    invoiceId: Yup.string().nullable(),
    otherRefNo: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    responsibleForCustoms: Yup.string()
      .nullable()
      .max(20, 'Max length allowed is 20')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    instructionsToBroker: Yup.string().nullable(),
    containerized: Yup.string().nullable(),
    billChargesTo: Yup.string().nullable(),
    portOfLoading: Yup.string().nullable(),
    flightDetails: Yup.string()
      .nullable()
      .max(20, 'Max length allowed is 20')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    placeOfDelivery: Yup.string()
      .nullable()
      .max(20, 'Max length allowed is 20')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    specialInstructions: Yup.string()
      .nullable()
      .max(200, 'Max length allowed is 200')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
  })
}

export default makeValidationSchema
