import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const regexDigitWithDecimal = ValidationRegex.regex13DigitWith2Decimal
  const errMsgDigitWithDecimal =
    'Please enter only numeric values with up to 13 digits and 2 decimal'
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  return Yup.object({
    totalInvoice: Yup.string().required(requiredMessage)
      .nullable()
      .max(16, 'Max length allowed is 16')
      .matches(regexDigitWithDecimal, errMsgDigitWithDecimal),
    nbciUSInvoiceShipmentSummary: Yup.array(),
    nbciusinvoiceSummaryReference: Yup.array()
  })
}

export default makeValidationSchema
