import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCIUSInvoiceKeys from 'src/constants/locale/key/NbciUsInvoice.js'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexDigitWithoutDecimal = '^[0-9]{0,4}$'
  const errMsgDigitWithoutDecimal =
    'Please enter only numeric values with up to 4 digits'
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Allowed special characters are  ' + ValidationRegex.errMsgAlphaNumeric

  return Yup.object({
    portCode: Yup.string().nullable(),
    entryDate: Yup.lazy((value) =>
    value === '' || value === null
      ? Yup.string().nullable()
      : Yup.date().nullable().typeError(dateTypeErrorMessage)
  ),
    entryNo: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    vinNumber: Yup.string()
      .nullable()
      .max(17, 'Max length allowed is 17')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    manufactureDate: Yup.lazy((value) =>
    value === '' || value === null
      ? Yup.string().nullable()
      : Yup.date().nullable().typeError(dateTypeErrorMessage)
  ),
    manufacturer: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    model: Yup.string()
      .nullable()
      .max(15, 'Max length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    modelYear: Yup.string()
      .nullable()
      .max(4, 'Max length allowed is 4')
      .matches(regexDigitWithoutDecimal, errMsgDigitWithoutDecimal),
    expiryDateOfCert: Yup.lazy((value) =>
    value === '' || value === null
      ? Yup.string().nullable()
      : Yup.date().nullable().typeError(dateTypeErrorMessage)
  ),
    dateForForm: Yup.lazy((value) =>
    value === '' || value === null
      ? Yup.string().nullable()
      : Yup.date().nullable().typeError(dateTypeErrorMessage)
  ),
    nameCompanyPhone: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(30, 'Max length allowed is 30'),
    vehicleTypeB: Yup.boolean().nullable(),
    vehicleTypeF: Yup.boolean().nullable(),
    vehicleTypeEe: Yup.boolean().nullable(),
    vehicleTypeFf: Yup.boolean().nullable(),
    vehicleTypeM: Yup.boolean().nullable(),
    vehicleTypeE: Yup.boolean().nullable(),
    vehicleTypeL: Yup.boolean().nullable(),
    vehicleTypeU: Yup.boolean().nullable(),
    vehicleTypeW: Yup.boolean().nullable(),
    vehicleTypeY: Yup.boolean().nullable(),
    vehicleTypeG: Yup.boolean().nullable(),
    vehicleTypeI: Yup.boolean().nullable(),
    vehicleTypeK: Yup.boolean().nullable(),
    vehicleTypeN: Yup.boolean().nullable(),
    vehicleTypeO: Yup.boolean().nullable(),
    vehicleTypeA: Yup.boolean().nullable(),
    vehicleTypeC: Yup.boolean().nullable(),
    vehicleTypeJ: Yup.boolean().nullable(),
    vehicleTypeZ: Yup.boolean().nullable(),
    vehicleTypeH: Yup.boolean().nullable(),
    vehicleTypeQ: Yup.boolean().nullable()
  })
}

export default makeValidationSchema
