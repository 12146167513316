import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCIUSInvoiceKeys from 'src/constants/locale/key/NbciUsInvoice.js'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Allowed special characters are  ' + ValidationRegex.errMsgAlphaNumeric
  // const regexAlphaNumericWithDash =
  //   '^[a-zA-Z0-9\\-]+$'
  // const errMsgAlphaNumericWithDash =
  //   'Only alphanumeric characters and dashes (-) allowed'
  const regexFDAProductCode =
    '^\\d{2}[a-zA-Z]{4}\\d{1}$|^\\d{2}[a-zA-Z]{3}\\d{1}[a-zA-Z]{1}$|^\\d{2}[a-zA-Z]{3}\\d{2}$|^\\d{2}[a-zA-Z]{5}$|^\\d{2}[a-zA-Z]{1}\\-{2}[a-zA-Z]{1}\\d{1}$|^\\d{2}[a-zA-Z]{1}\\-{2}\\d{1}[a-zA-Z]{1}$|^\\d{2}[a-zA-Z]{1}\\-{2}[a-zA-Z]{2}$|^\\d{2}[a-zA-Z]{1}\\-{2}\\d{2}$|^\\d{2}[a-zA-Z]{1}\\-{1}[a-zA-Z]{2}\\d{1}$|^\\d{2}[a-zA-Z]{1}\\-{1}[a-zA-Z]{1}\\d{1}[a-zA-Z]{1}$|^\\d{2}[a-zA-Z]{1}\\-{1}[a-zA-Z]{1}\\d{2}$|^\\d{2}[a-zA-Z]{1}\\-{1}[a-zA-Z]{3}$|^\\d{2}[a-zA-Z]{2}\\-{1}[a-zA-Z]{1}\\d{1}$|^\\d{2}[a-zA-Z]{2}\\-{1}\\d{1}[a-zA-Z]{1}$|^\\d{2}[a-zA-Z]{2}\\-{1}\\d{2}$|^\\d{2}[a-zA-Z]{2}\\-{1}[a-zA-Z]{2}$'
  const errMsgFDAProductCode =
    'Invalid FDA Product Code Format'    
  const regexAlpha = '^[0-9]*$'
  let errMsgAlpha = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_NUMERIC
  )
  // const errMsgAlpha = 'Only Numeric Values are allowed'
  const phonePattern = '^[0-9 -]*$'
  let phoneErrorMessage = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_NUMERIC_HYPHEN_SPACE
  )
  // const phoneErrorMessage =
  //   'Please enter valid phone number[digits with space or -]'
  const regexFax = '^[0-9\\-\\+\\(\\)\\ ]+$'
  // const errMsgFax = 'Please enter valid Fax'
  let errMsgFax = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR_FAX
  )
  const regexDigitWithDecimal = ValidationRegex.regex6DigitWith4Decimal
  const errMsgDigitWithDecimal =
    'Please enter only numeric values with up to 6 digits and 4 decimal'
  const regexDigitWith7Decimal = ValidationRegex.regex7DigitWith7Decimal
  const errMsgDigitWith7Decimal =
    'Please enter only numeric values with up to 7 digits and 7 decimal'
  const regexDigitWith4Decimal = ValidationRegex.regex7DigitWith4Decimal
  const errMsgDigitWith4Decimal =
    'Please enter only numeric values with up to 7 digits and 4 decimal'
  // const errMsgPostalCode = 'Please enter valid Postal Code'
  const postalCodePattern = '^[a-zA-Z0-9-., ]*$'
  let errMsgPostalCode = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR_COMA_DOT_DASH
  )

  return Yup.object({
    partNumber: Yup.string()
      .nullable()
      .max(25, 'Max Length allowed is 25')
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    productDescription: Yup.string()
      .nullable()
      .max(160, 'Max Length allowed is 160')
      .required(requiredMessage)
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    fdaProductCode: Yup.string()
      .nullable()
      .max(8, 'Max Length allowed is 08')
      .matches(regexFDAProductCode, errMsgFDAProductCode),
    countryOfOriginCode: Yup.string().nullable().required(requiredMessage),
    stateOfOrigin: Yup.string().nullable(),
    quantityInvoiced: Yup.string()
      .nullable()
      .max(11, 'Max Length allowed is 11')
      .required(requiredMessage)
      .matches(regexDigitWithDecimal, errMsgDigitWithDecimal),
    uomQuantity: Yup.string().nullable().required(requiredMessage),
    htsCode: Yup.string()
      .nullable()
      .matches(regexAlpha, errMsgAlpha)
      .max(15, 'Max Length allowed is 15'),
    unitPrice: Yup.string()
      .nullable()
      .max(15, 'Max Length allowed is 15')
      .required(requiredMessage)
      .matches(regexDigitWith7Decimal, errMsgDigitWith7Decimal),
    netWeight: Yup.string()
      .nullable()
      .max(14, 'Max Length allowed is 14')
      .matches(regexDigitWith4Decimal, errMsgDigitWith4Decimal),
    netWeightUom: Yup.string().nullable(),
    grossWeight: Yup.string()
      .nullable()
      .max(14, 'Max Length allowed is 14')
      .matches(regexDigitWith4Decimal, errMsgDigitWith4Decimal),
    grossWeightUom: Yup.string().nullable(),
    lineItemTotal: Yup.string()
      .nullable()
      .max(20, 'Max Length allowed is 20'),
    markPackLoad: Yup.string()
      .nullable()
      .max(80, 'Max Length allowed is 80')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    noOfPackages: Yup.string()
      .nullable()
      .max(15, 'Max Length allowed is 15')
      .matches(regexAlpha, errMsgAlpha),
    packageType: Yup.string()
      .nullable()
      .max(15, 'Max Length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    productSize: Yup.string()
      .nullable()
      .max(15, 'Max Length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    admname: Yup.string()
      .nullable()
      .max(15, 'Max Length allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    admTelephoneNumber: Yup.string()
      .nullable()
      .max(30, 'Max Length allowed is 30')
      .matches(phonePattern, phoneErrorMessage),
    naftaCriteria: Yup.string().nullable(),
    dateRangeFrom:  Yup.lazy((value) =>
    value === '' || value === null
      ? Yup.string().nullable()
      : Yup.date().nullable().typeError(dateTypeErrorMessage)
  ),
    dateRangeTo: Yup.lazy((value) =>
    value === '' || value === null
      ? Yup.string().nullable()
      : Yup.date().nullable().typeError(dateTypeErrorMessage)
  ),
    netCost: Yup.string().nullable(),
    isProducer: Yup.string().nullable(),
    lineAmount: Yup.string().nullable(),
    includeProdNafta: Yup.string().nullable(),
    notAPrducerOfGoods: Yup.string().nullable(),
    templateIndicator: Yup.string().nullable(),
    manufacturingPartyName: Yup.string()
      .trim()
      .max(60, 'Max length allowed is 60')
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    manufacturingMID: Yup.string()
      .trim()
      .max(20, 'Max length allowed is 20')
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    manufacturingFDARegNum: Yup.string()
      .trim()
      .max(60, 'Max length allowed is 60')
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    growerPartyName: Yup.string()
      .trim()
      .max(60, 'Max length allowed is 60')
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    growerMID: Yup.string()
      .trim()
      .max(20, 'Max length allowed is 20')
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    growerFDARegNumber: Yup.string()
      .trim()
      .max(60, 'Max length allowed is 60')
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    consolidatorPartyName: Yup.string()
      .trim()
      .max(60, 'Max length allowed is 60')
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    consolidatorMID: Yup.string()
      .trim()
      .max(20, 'Max length allowed is 20')
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    consolidatorFDARegNumber: Yup.string()
      .trim()
      .max(60, 'Max length allowed is 60')
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    consPartyName: Yup.string()
      .nullable()
      .max(60, 'Max length allowed is 60')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    consPartyIdentifierNumber: Yup.string()
      .nullable()
      .max(20, 'Max length allowed is 20')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    consPartyAddress: Yup.string()
      .nullable()
      .max(110, 'Max length allowed is 110')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    consPartyCity: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    consPartyState: Yup.string().nullable(),
    consPartyCountry: Yup.string().nullable(),
    consPartyPostalCode: Yup.string()
      .nullable()
      .max(10, 'Max length allowed is 10')
      .matches(postalCodePattern, errMsgPostalCode),
    consPartyLocalContactName: Yup.string()
      .nullable()
      .max(60, 'Max length allowed is 60')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    consPartyTelephoneNumber: Yup.string()
      .nullable()
      .max(30, 'Max Length allowed is 30')
      .matches(phonePattern, phoneErrorMessage),
    consPartyFax: Yup.string()
      .nullable()
      .max(35, 'Max Length allowed is 35')
      .matches(regexFax, errMsgFax),
    consPdba: Yup.string()
      .nullable()
      .max(50, 'Max length allowed is 50')
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    consPfdaRegNum: Yup.string()
      .nullable()
      .max(20, 'Max length allowed is 20')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    consPartyEmail: Yup.string()
      .nullable()
      .max(50, 'Max length allowed is 50')
      .email('Invalid email')
      .matches('[A-Za-z0-9+_.-]+@(.+)$', 'Enter Email in valid pattern'),
    nbciusinvoiceDetailsReference: Yup.array(),
    nbciusinvoiceDetailsService: Yup.array(),
    nbciusinvoiceDetailsDOTForm: Yup.array(),
    nbciusinvoiceDetailsFDAForm: Yup.array(),
    nbciusinvoiceDetailsFCCForm: Yup.array(),
    nbciusinvoiceDetailsEPA35201Form: Yup.array(),
    nbciusinvoiceDetailsEPA352021Form: Yup.array()
  })
}

export default makeValidationSchema
