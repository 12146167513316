import { Yup } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCIUSInvoiceKeys from 'src/constants/locale/key/NbciUsInvoice.js'

function makeValidationSchema(translate) {
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Allowed special characters are  ' + ValidationRegex.errMsgAlphaNumeric
  const regexNumericwith3Decimal = ValidationRegex.regex3DigitWith3Decimal
  const errMsgAlphaDecimal = 'Allowed values are 3 digits with 3 decimals'
  const regexNumericwith7Decimal = ValidationRegex.regex7DigitWith1Decimal
  const errMsgAlphaDecimal1 = 'Allowed values are 7 digits with 1 decimals'

  return Yup.object({
    noOfPackages: Yup.string()
      .max(10, 'Max length allowed is 10')
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    packageType: Yup.string().nullable(),
    packageDimL: Yup.string()
      .max(7, 'Max length allowed is 06 [3 digits and 3 decimals]')
      .nullable()
      .matches(regexNumericwith3Decimal, errMsgAlphaDecimal),
    packageDimW: Yup.string()
      .max(7, 'Max length allowed is 06 [3 digits and 3 decimals] ')
      .nullable()
      .matches(regexNumericwith3Decimal, errMsgAlphaDecimal),
    packageDimH: Yup.string()
      .max(7, 'Max length allowed is 06 [3 digits and 3 decimals]')
      .nullable()
      .matches(regexNumericwith3Decimal, errMsgAlphaDecimal),
    dimensionsUOM: Yup.string().nullable(),
    prodDesc: Yup.string()
      .max(30, 'Max length allowed is 30')
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    grossWeight: Yup.string()
      .max(9, 'Max length allowed is 08 [7 digits and 1 decimal]')
      .nullable()
      .matches(regexNumericwith7Decimal, errMsgAlphaDecimal1),
    grossWeightUOM: Yup.string().nullable(),
    freightClass: Yup.string().nullable()
  })
}

export default makeValidationSchema
