import React, { useRef, useEffect } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../constants/locale/key/NbciUsInvoice'
import FormProperties from './InvoiceSummaryFormProperties'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Divider, Grid, Tooltip } from '@material-ui/core'
import InvoiceSummaryFieldsPlaceholder from './InvoiceSummaryFieldsPlaceholder'
import CngSection from '../../../../components/cngcomponents/CngSection'
import _ from 'lodash'
import useCustomYupValidationResolver from 'src/components/aciacehighway/hooks/useCustomYupValidationResolver'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues, makeValidationSchema } = FormProperties.formikProps

function InvoiceSummarySection({ invoiceData, isPendingSave, isPendingSaveShipmentSummary, invoiceStateData, isNotSaved }) {
  const { getValues, setValue, watch } = useFormContext()
  const invoiceSummary = watch('invoiceSummary')
  const invoiceDetailsWatch = watch('invoiceDetails')

  const generatedIndex = useRef(0)
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const calculateTotal = () => {
    let lineAmountTotal = 0;
    let RefTotalAmount=0;
    let totalAmount=0;
    if(invoiceDetailsWatch){
      invoiceDetailsWatch.forEach((obj) => {
        const lineAmountvalue = parseFloat(obj.lineAmount);
        lineAmountTotal +=lineAmountvalue;
      });
    }
    if (invoiceData.current.invoiceSummary && invoiceData.current.invoiceSummary.length > 0) {
      invoiceData.current.invoiceSummary.forEach((obj1) => {
        if(obj1.nbciusinvoiceSummaryReference)
        obj1.nbciusinvoiceSummaryReference.forEach((innerObj) => {
          const refSummary= parseFloat(innerObj.refAmount);
          RefTotalAmount +=refSummary;
      });
      totalAmount=lineAmountTotal+RefTotalAmount;
      obj1.totalInvoice = parseFloat(totalAmount.toFixed(2));
      });
      setValue('invoiceSummary', invoiceData.current.invoiceSummary)
      // invoiceData.current.invoiceSummary = invoiceSummary
    } else {
      const newInvoiceSummary = [...getValues('invoiceSummary'), 
      {"totalInvoice":"","nbciUSInvoiceShipmentSummary":[],"nbciusinvoiceSummaryReference":[]}]
      setValue('invoiceSummary', newInvoiceSummary)
        invoiceData.current.invoiceSummary = newInvoiceSummary
      console.log(invoiceSummary)
    }
  };

  useEffect(() => { //for invoice detail
    calculateTotal();
  }, [invoiceStateData]); 

  function makeTranslatedTextsObject() {
    const invoiceSummary = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.InvoiceSummary.TITLE
    )

    return { invoiceSummary }
  }

  function handleAddInvoiceSummary(data) {
    const invoiceSummary = [...getValues('invoiceSummary'), data].map(
      (invoice, index) => ({ ...invoice, _id: index })
    )

    setValue('invoiceSummary', invoiceSummary)
    invoiceData.current.invoiceSummary = invoiceSummary
    calculateTotal();
    isNotSaved.current = true
  }

  function getClonedInvoiceSummary(invoiceSummary) {
    let result = { ...invoiceSummary, id: undefined }

    result.nbciUSInvoiceShipmentSummary.forEach((shipment) => {
      shipment['id'] = undefined
    })

    result.nbciusinvoiceSummaryReference.forEach((reference) => {
      reference['id'] = undefined
    })

    isNotSaved.current = true
    return result
  }

  function handleDeleteInvoiceSummary(index) {
    const invoiceSummary = [...getValues('invoiceSummary')]

    invoiceSummary.splice(index, 1)

    setValue('invoiceSummary', invoiceSummary)
    invoiceData.current.invoiceSummary = invoiceSummary
    calculateTotal();
    isNotSaved.current = true
  }

  function handleEditInvoiceSummary(index, data) {
    const invoiceSummary = [...getValues('invoiceSummary')]

    invoiceSummary[index] = data

    setValue('invoiceSummary', invoiceSummary)
    invoiceData.current.invoiceSummary = invoiceSummary
    calculateTotal();
    isNotSaved.current = true
  }

  return (
    <CngSection title={translatedTextsObject.invoiceSummary}>
      <Grid container spacing={2}>
        {_.isEmpty(invoiceSummary) ? (
          <Grid item xs={12}>
            <InvoiceSummaryFieldsPlaceholder onAdd={handleAddInvoiceSummary} />
          </Grid>
        ) : (
          invoiceSummary.map((summary, index) => (
            <React.Fragment key={++generatedIndex.current}>
              <Grid item xs={12}>
                <InvoiceSummaryEntry
                  invoiceSummary={summary}
                  onAdd={handleAddInvoiceSummary}
                  onClone={(data) =>
                    handleAddInvoiceSummary(getClonedInvoiceSummary(data))
                  }
                  onDelete={() => handleDeleteInvoiceSummary(index)}
                  onEdit={(data) => handleEditInvoiceSummary(index, data)}
                  isPendingSave={isPendingSave}
                  isPendingSaveShipmentSummary={isPendingSaveShipmentSummary}
                />
              </Grid>
              {index + 1 !== invoiceSummary.length && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CngSection>
  )
}

function InvoiceSummaryEntry(props) {
  const { invoiceSummary, onAdd, onClone, onDelete, onEdit, isPendingSave, isPendingSaveShipmentSummary } = props

  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const methods = useForm({
    defaultValues: invoiceSummary,
    resolver: useCustomYupValidationResolver(makeValidationSchema(translate))
  })
  const {
    formState: { isDirty }
  } = methods

  function handleEditInvoiceSummary(data) {
    methods.reset(data)
    onEdit(data)
  }

  useEffect(() => {
    isPendingSave.current = isDirty
  }, [isDirty])

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12}>
          <FormProperties.Fields isPendingSaveShipmentSummary={isPendingSaveShipmentSummary} />
        </Grid>
        <Grid item xs={12}>
          <Grid justify='flex-end' container spacing={1}>
            {isDirty ? (
              <>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    onClick={() => methods.reset()}
                    size='medium'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    onClick={methods.handleSubmit(handleEditInvoiceSummary)}
                    size='medium'
                  >
                    Save changes
                  </CngButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Add'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'plus']}
                        onClick={() => onAdd(initialValues)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Clone'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'copy']}
                        onClick={() => onClone(invoiceSummary)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Delete'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'trash']}
                        onClick={() => onDelete(invoiceSummary)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default InvoiceSummarySection
