import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCIUSInvoiceKeys from 'src/constants/locale/key/NbciUsInvoice.js'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexAlphaNumeric = ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Please enter alpha numeric values other than <,>,*,~'

  return Yup.object({
    usCustomBroker: Yup.string()
      .nullable()
      .max(15, 'Max value allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    otherRefNo: Yup.string()
      .nullable()
      .max(15, 'Max value allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    priorNoticeNo: Yup.string()
      .nullable()
      .max(15, 'Max value allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    billCustomChargsSh: Yup.string().nullable(),
    billCustomChargsCn: Yup.string().nullable(),
    billCustomChargsOt: Yup.string().nullable(),
    vesselName: Yup.string()
      .nullable()
      .max(15, 'Max value allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    dateOfExport: Yup.mixed()
    .nullable()
    .default(null)
    .test('is-date', 'Invalid date format', (value) => {
      if (value === null || value === '') {
        return true // Allow null or empty fields
      }
      return moment(value, 'YYYY-MM-DD', true).isValid()
    }),
    specifyOther: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    flightNo: Yup.string()
      .nullable()
      .max(15, 'Max value allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    tripNo: Yup.string()
      .nullable()
      .max(15, 'Max value allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    frieghtIncluded: Yup.string().nullable(),
    exportPermitNumber: Yup.string()
      .nullable()
      .max(15, 'Max value allowed is 15')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
  })
}

export default makeValidationSchema
