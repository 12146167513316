import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import FormProperties from './LaceyActDetailsFormProperties'
import CngSection from '../../../../../components/cngcomponents/CngSection'
import LaceyActDetailsFieldsPlaceholder from './LaceyActDetailsFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip } from '@material-ui/core'
import useCustomYupValidationResolver from '../../../../../components/aciacehighway/hooks/useCustomYupValidationResolver'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues, makeValidationSchema } = FormProperties.formikProps

function LaceyActDetailsSection() {
  const generatedIndex = useRef(0)
  const { getValues, setValue, watch } = useFormContext()
  
  const laceyActDetails = watch('nbciusinvoiceLaceyActDetails')

  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function handleAddLaceyActDetails(data) {
    const laceyActDetails = [...getValues('nbciusinvoiceLaceyActDetails'), data]

    setValue('nbciusinvoiceLaceyActDetails', laceyActDetails)
  }

  function handleDeleteLaceyActDetails(index) {
    const laceyActDetails = [...getValues('nbciusinvoiceLaceyActDetails')]

    laceyActDetails.splice(index, 1)

    setValue('nbciusinvoiceLaceyActDetails', laceyActDetails)
  }

  function handleEditLaceyActDetails(index, data) {
    const laceyActDetails = [...getValues('nbciusinvoiceLaceyActDetails')]

    laceyActDetails[index] = data

    setValue('nbciusinvoiceLaceyActDetails', laceyActDetails)
  }

  function makeTranslatedTextsObject() {
    const laceyActDetailsTitle = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceLaceyActDetails.TITLE
    )

    return { laceyActDetailsTitle }
  }

  return (
    <CngSection title={translatedTextsObject.laceyActDetailsTitle}>
      <Grid container spacing={2}>
        {_.isEmpty(laceyActDetails) ? (
          <Grid item xs={12}>
            <LaceyActDetailsFieldsPlaceholder
              onAdd={handleAddLaceyActDetails}
            />
          </Grid>
        ) : (
          laceyActDetails.map((detail, index) => (
            <React.Fragment key={++generatedIndex.current}>
              <Grid item xs={12}>
                <LaceyActDetailsEntry
                  laceyActDetails={detail}
                  onAdd={handleAddLaceyActDetails}
                  onDelete={() => handleDeleteLaceyActDetails(index)}
                  onEdit={(data) => handleEditLaceyActDetails(index, data)}
                />
              </Grid>
              {index + 1 !== laceyActDetails.length && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CngSection>
  )
}

function LaceyActDetailsEntry(props) {
  const { laceyActDetails, onAdd, onDelete, onEdit } = props

  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const methods = useForm({ defaultValues: laceyActDetails, resolver: useCustomYupValidationResolver(makeValidationSchema(translate)) })
  const {
    formState: { isDirty }
  } = methods

  function handleEditLaceyActDetails(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12}>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12}>
          <Grid justify='flex-end' container spacing={1}>
            {isDirty ? (
              <>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    onClick={() => methods.reset()}
                    size='medium'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    onClick={methods.handleSubmit(handleEditLaceyActDetails)}
                    size='medium'
                  >
                    Save changes
                  </CngButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Add'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'plus']}
                        onClick={() => onAdd(initialValues)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Clone'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'copy']}
                        onClick={() =>
                          onAdd({ ...laceyActDetails, id: undefined })
                        }
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Delete'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'trash']}
                        onClick={() => onDelete(laceyActDetails)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default LaceyActDetailsSection
