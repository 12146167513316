import { Yup } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCIUSInvoiceKeys from 'src/constants/locale/key/NbciUsInvoice.js'

function makeValidationSchema(translate) {
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.NBCI_US_INVOICE,
    NBCIUSInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Please enter alpha numeric values other than <,>,*,~'

  return Yup.object({
    referenceType: Yup.string().nullable(),
    referenceIdentification: Yup.string()
      .nullable()
      .max(20, 'Max length allowed is 20')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric),
    refDescription: Yup.string()
      .nullable()
      .max(30, 'Max length allowed is 30')
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
  })
}

export default makeValidationSchema
